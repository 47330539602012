import React from 'react'
import SideMenu from '@/components/SideMenu'
import ScrollButtonToTop from '@/components/ScrollButtonToTop'
import classNames from 'classnames'

interface Props {
  title?: string
  hideSideMenu?: boolean
  footerOffsetY: number
}

const Content: React.FC<Props> = ({ title, hideSideMenu, footerOffsetY, children }) => {
  const hasTitle = title !== ''
  const [isTop, setIsTop] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  // const offsetScrollHidden = hideSideMenu ? 360 : -360
  const offsetScrollHidden = () => {
    if (hasTitle) {
      return 0
    }
    return hideSideMenu ? 360 : -360
  }

  const onScroll = () => {
    if (containerRef.current) {
      const offsetY = containerRef.current.getBoundingClientRect().y
      setIsTop(offsetY < offsetScrollHidden())
    } else {
      setIsTop(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  })

  return (
    <>
      {hasTitle && (
        <div className="bg-white text-center text-purple-300 pt-6 pb-6 md:pt-28 md:pb-12">
          <h2 className="text-3xl font-bold">{title}</h2>
        </div>
      )}
      <div ref={containerRef} className="bg-ivory text-purple-300">
        <div className="container mx-auto">
          {hideSideMenu ? (
            children
          ) : (
            <div
              className={classNames(
                hasTitle ? '' : 'md:pt-10',
                'block md:flex space-between pt-10 pb-10 md:pb-0 px-5 md:px-0'
              )}
            >
              <div className="w-full md:w-9/12 pr-0 pb-10 md:px-10">{children}</div>
              <div className={classNames(hasTitle ? 'pb-10' : 'pt-10', 'w-full md:w-3/12')}>
                <SideMenu />
              </div>
            </div>
          )}
        </div>
        <div className={classNames({ 'lg:block': isTop }, 'hidden fixed bottom-0 right-0')}>
          <ScrollButtonToTop footerOffsetY={footerOffsetY} />
        </div>
      </div>
    </>
  )
}

Content.defaultProps = {
  title: '',
  hideSideMenu: false,
}

export default Content
