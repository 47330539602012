import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'
import NavMenu from '@/components/NavMenu'
import NavMenuSp from '@/components/NavMenuSp'
import Content from '@/components/Content'
import Footer from '@/components/Footer'
import { parseMetaTitle } from '@/tools/meta'
import { LayoutQuery } from '../../types/graphql-types'

interface Props {
  firstViewComponent?: React.ReactNode
  title?: string
  hideNavMenu?: boolean
  hideSideMenu?: boolean
}

const query = graphql`
  query Layout {
    site {
      siteMetadata {
        title
        address {
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        telephone
        faxNumber
        serviceName
      }
    }
  }
`

const Layout: React.FC<Props> = ({ firstViewComponent, title, hideNavMenu, hideSideMenu, children }) => (
  <StaticQuery
    query={query}
    render={(data: LayoutQuery) => {
      const [footerOffsetY, setFooterOffsetY] = React.useState(-1)
      const footerRef = React.useRef<HTMLDivElement>(null)

      const onScroll = () => {
        // Detect Footer Scroll
        if (footerRef.current) {
          const displayedFooterHeighet = window.innerHeight - footerRef.current.getBoundingClientRect().y
          setFooterOffsetY(Math.ceil(displayedFooterHeighet))
        } else {
          setFooterOffsetY(-1)
        }
      }

      React.useEffect(() => {
        document.addEventListener('scroll', onScroll)
        return () => document.removeEventListener('scroll', onScroll)
      })

      const meta = data?.site?.siteMetadata
      let h1Title = parseMetaTitle(meta)
      if (title !== '') {
        h1Title = `${title} | ${h1Title}`
      }

      return (
        <>
          <BrowserView>
            <NavMenu title={h1Title} hideNavMenu={hideNavMenu} />
          </BrowserView>
          <MobileView>
            <NavMenuSp title={h1Title} />
          </MobileView>

          {firstViewComponent}

          <Content hideSideMenu={hideSideMenu} footerOffsetY={footerOffsetY} title={title}>
            {children}
          </Content>

          <div ref={footerRef}>
            <Footer />
          </div>
        </>
      )
    }}
  />
)

Layout.defaultProps = {
  title: '',
  hideNavMenu: false,
  hideSideMenu: false,
}

export default Layout
