export const siteMetadata = {
  title: `さくっこ`,
  siteUrl: process.env.SITE_URL || 'https://sakukko.com',
  description:
    '一人ひとり違いのあるお子さま方にゆっくりと関わりながら手作り工作や遊びを通し「育つ力」を見守りながら支援してまいります。',
  keywords: ['さくっこ', '兵庫県', '明石市', '藤江', '児童発達支援', '放課後等デイサービス'],
  address: {
    '@type': 'PostalAddress',
    addressLocality: '兵庫県',
    addressRegion: '明石市藤江',
    postalCode: '673-0044',
    streetAddress: '968-53',
    addressCountry: 'JP',
  },
  telephone: '078-219-3254',
  faxNumber: '078-219-3146',
  image: 'hero-ogp.png',
  businessNumber: '2852000070',
  staffs: ['サービス管理者', '児童発達支援管理責任者', '准看護師', '保育士', '児童指導員'],
  instagramUserId: '31794190091', // @see https://github.com/oorestisime/gatsby-source-instagram#public-scraping-for-posts
  instagramUsername: 'sakukko.ajuga',
  serviceName: '児童発達支援・放課後等デイサービス',
}
