import React from 'react'
import { img, instagram } from '@/tools/image'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { SeoQuery } from '../../types/graphql-types'
import { siteMetadata } from '../siteMetaData'

interface Props {
  title?: string
  description?: string
  lang?: string
  meta?: any[]
  keywords?: string[]
  image?: string
  pathname?: string
}

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        title
        siteUrl
        description
        keywords
        address {
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        image
        telephone
        faxNumber
        instagramUsername
        serviceName
      }
    }
  }
`

export const SEO: React.StatelessComponent<Props> = (props) => {
  const { description, lang, meta, keywords, title, image, pathname } = props

  return (
    <StaticQuery
      query={query}
      render={(data: SeoQuery) => {
        const metaData = data?.site?.siteMetadata
        const service = `${metaData?.address?.addressRegion}の${metaData?.serviceName}`
        const metaTitle = `${title} | ${metaData?.title} ${service}`
        const metaUrl = `${metaData?.siteUrl}${pathname || '/'}`
        const metaDescription = description || `${service}。${metaData?.description}`
        const metaKeywords = keywords?.length ? keywords : metaData?.keywords
        const metaImage = `${metaData?.siteUrl}${img(image || metaData?.image || '')}`
        const schemaOrgJSONLD = [
          {
            '@context': 'http://schema.org',
            '@type': 'LocalBusiness',
            '@id': metaUrl,
            url: metaUrl,
            name: metaData?.title,
            telephone: metaData?.telephone,
            faxNumber: metaData?.faxNumber,
            address: metaData?.address,
            // geo: "35.6672704,139.7133435", //所在地の座標
            logo: {
              '@type': 'ImageObject',
              url: `${metaData?.siteUrl}${img('logo@2x.png')}`,
              caption: metaData?.title,
            },
            image: {
              '@type': 'ImageObject',
              url: `${metaData?.siteUrl}${img('hero-ogp.png')}`,
              caption: metaTitle,
            },
            sameAs: instagram(metaData?.instagramUsername || ''),
            photo: `${metaData?.siteUrl}${img('appearance@2x.png')}`,
          },
        ]

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            bodyAttributes={{
              class: 'pt-12 md:pt-0 leading-normal tracking-normal',
            }}
            title={title || siteMetadata.title}
            titleTemplate={title === '' ? siteMetadata.title : `%s | ${metaData?.title} ${service}`}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                property: 'twitter:url',
                content: metaUrl,
              },
            ]
              .concat(
                metaKeywords && metaKeywords.length > 0
                  ? {
                      name: 'keywords',
                      content: metaKeywords.join(','),
                    }
                  : []
              )
              .concat(meta || [])}
          >
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  title: '',
  lang: 'ja',
  meta: [],
  keywords: [],
  image: '',
  pathname: '',
}
