import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { MENUS_MAIN } from '@/constants'
import { parseTel, parseAddress } from '@/tools/meta'
import { img } from '@/tools/image'
import { FooterQuery } from '../../types/graphql-types'
import FooterMenu from '@/components/FooterMenu'

interface Props {}

const query = graphql`
  query Footer {
    site {
      siteMetadata {
        description
        address {
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        telephone
        faxNumber
        businessNumber
      }
    }
  }
`

const Footer: React.FC<Props> = () => (
  <StaticQuery
    query={query}
    render={(data: FooterQuery) => {
      const meta = data?.site?.siteMetadata
      const addressStyle = isMobile
        ? {}
        : {
            borderRight: '1px solid white',
          }
      return (
        <footer className="bg-purple-300 relative">
          <img
            className="hidden md:block absolute my-auto top-0 bottom-0 left-0  -ml-16"
            src={img('footer-bear@2x.png')}
            style={{ height: '80%' }}
          />
          <div className="container mx-auto text-white">
            <div className="block md:flex relative items-center space-between pt-10 py-20 md:py-10">
              <img className="h-auto sm:h-12 absolute bottom-0 ml-0 sm:ml-12" src={img('footer-weeds.png')} />
              <div className="w-full md:w-2/3 px-0 md:px-10">
                <div className="block md:flex space-between">
                  <div className="w-full md:w-5/12">
                    <img className="h-20 w-auto ml-auto mr-auto md:mr-8" src={img('logo-white@2x.png')} />
                  </div>
                  <div
                    className="w-full md:w-7/12 relative text-center md:text-left tracking-wide leadning-normal md:leading-none my-10 md:my-0"
                    style={addressStyle}
                  >
                    <p className="text-md md:text-lg">
                      〒{meta?.address?.postalCode} {parseAddress(meta?.address)}
                    </p>
                    <p className="text-md md:text-lg">
                      TEL.{` `}
                      <a href={`tel:${parseTel(meta?.telephone || '')}}`}>{meta?.telephone}</a>
                      {` `}
                      FAX.{` `}
                      {meta?.faxNumber}
                    </p>
                    <p className="text-sm md:text-md mt-2 md:mt-6">兵庫県事業者番号:{meta?.businessNumber}</p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3">
                <div className="flex text-md md:text-lg">
                  <div className="w-1/2 ml-10">
                    <FooterMenu menus={MENUS_MAIN.slice(0, 3)} ulClass="list-disc" liClass="mb-2 md:mb-4" />
                  </div>
                  <div className="w-1/2 ml-10 md:ml-0">
                    <FooterMenu
                      menus={MENUS_MAIN.slice(3, MENUS_MAIN.length)}
                      ulClass="list-disc"
                      liClass="mb-2 md:mb-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  />
)

Footer.defaultProps = {}

export default Footer
