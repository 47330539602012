import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { SideMenuQuery } from '../../types/graphql-types'
import { parseAddress } from '@/tools/meta'
import Constants from '@/constants'
import { img } from '@/tools/image'
import ImageContact from '@/components/ImageContact'
import TelephoneLink from '@/components/TelephoneLink'

interface Props {}

const query = graphql`
  query SideMenu {
    site {
      siteMetadata {
        title
        address {
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        telephone
        faxNumber
        serviceName
      }
    }
  }
`

const SideMenu: React.FC<Props> = () => (
  <StaticQuery
    query={query}
    render={(data: SideMenuQuery) => {
      const meta = data?.site?.siteMetadata
      return (
        <>
          {/* Menu */}
          <div className="font-bold tracking-widest">
            <p className="text-xl ml-5 mb-3">MENU</p>
            <ul className="">
              {Constants.MENUS_SIDE.map((menu, index) => (
                <li
                  className={classNames(
                    'py-4 px-5 border border-b-1 border-t-0 border-l-0 border-r-0 border-purple-300 relative',
                    index === 0 ? 'border-t-2' : '',
                    index === Constants.MENUS_SIDE.length - 1 || menu.text === '' ? 'border-b-2' : ''
                  )}
                  key={index}
                >
                  <Link to={menu.href} className="text-2xl">
                    {menu.text ? (
                      <span>・{menu.text}</span>
                    ) : (
                      <>
                        <span className="invisible">SPACING</span>
                        <img className="w-auto absolute bottom-0 right-0" src={img('weeds.png')} />
                      </>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact */}
          <div className="my-10 md:my-12">
            <ImageContact />
          </div>

          {/* Address */}
          <div className="max-w-sm rounded overflow-white bg-white shadow-lg">
            <img className="w-full" src={img('appearance@2x.png')} alt={`外観：${meta?.title}`} />
            <div className="px-6 py-3">
              <h3 className="text-md font-bold">{meta?.serviceName}</h3>
              <h3 className="text-3xl font-bold tracking-widest mb-2">{meta?.title}</h3>
              <div className="text-xl tracking-wider">
                <p>〒{meta?.address?.postalCode}</p>
                <p className="mb-2">{parseAddress(meta?.address)}</p>
                <p>
                  <TelephoneLink />
                </p>
                <p>FAX. {meta?.faxNumber}</p>
              </div>
              <p className="mt-6 mb-4">
                <Link to="/about">
                  <span className="bg-purple-300 text-white font-bold px-6 py-3 md:py-2">アクセス ▶︎</span>
                </Link>
              </p>
            </div>
          </div>

          {/* Address */}
          <div className="mt-6">
            <a href="/pdf/self-assessment2024.pdf" target="_blank">
              <img className="w-auto" src={img('survey-cta@2x.png')} alt="利用者アンケート結果" />
            </a>
            <div className="">
              <div className="pb-2 border-b border-purple-300">過去の利用者アンケートと自己評価</div>
              <div className="pt-4">
                <ul className="list-disc pl-4">
                  <li>
                    <a href="/pdf/self-assessment2023.pdf" target="_blank">
                      <span>2023年</span>
                    </a>
                  </li>
                  <li>
                    <a href="/pdf/self-assessment2022.pdf" target="_blank">
                      <span>2022年</span>
                    </a>
                  </li>
                  <li>
                    <a href="/pdf/self-assessment2021.pdf" target="_blank">
                      <span>2021年</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )
    }}
  />
)

SideMenu.defaultProps = {}

export default SideMenu
