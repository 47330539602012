import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import useOnClickOutside from 'use-onclickoutside'
import classNames from 'classnames'
import { img } from '@/tools/image'
import Constants from '@/constants'

interface Props {
  title: string
}

const NavMenuSp: React.FC<Props> = ({ title }) => {
  const navRef = useRef(null)
  const [isToggled, setToggled] = useState(false)
  const toggle = () => setToggled(!isToggled)

  useOnClickOutside(navRef, () => {
    setToggled(false)
  })

  return (
    <nav id="header" className="fixed w-full z-30 top-0 bg-purple-300" ref={navRef}>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link className="no-underline hover:no-underline font-bold text-2xl" to="/">
            <h1>
              <img className="h-8" src={img('logo-white@2x.png')} alt={title} />
            </h1>
          </Link>
        </div>

        <div className="block lg:hidden pr-4">
          <button id="nav-toggle" onClick={toggle} className="flex items-center p-1 text-white hover:text-white">
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>メニュー</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          id="nav-content"
          className={classNames(
            'w-full flex-grow mt-2 lg:mt-0 bg-purple-300 text-white p-4 z-20 shadow-lg',
            isToggled ? '' : 'hidden'
          )}
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {Constants.MENUS_NAV_SP.map((menu) => (
              <li className="mr-3" key={menu.text}>
                <Link
                  className="inline-block text-white no-underline hover:text-white hover:text-underline py-2 px-4"
                  to={menu.href}
                >
                  {menu.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  )
}

export default NavMenuSp
