import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { BrowserView, MobileView } from 'react-device-detect'
import { img } from '@/tools/image'
import { parseTel } from '@/tools/meta'
import { ImageContactQuery } from '../../types/graphql-types'

interface Props {
  className?: string
  style?: any
}

const query = graphql`
  query ImageContact {
    site {
      siteMetadata {
        telephone
      }
    }
  }
`

const ImageContact: React.FC<Props> = ({ className, style }) => (
  <StaticQuery
    query={query}
    render={(data: ImageContactQuery) => {
      const meta = data?.site?.siteMetadata
      const imgElement = <img className={className} src={img('contact@2x.png')} alt="お問い合わせ" style={style} />
      return (
        <>
          <BrowserView>{imgElement}</BrowserView>
          <MobileView>
            <a href={`tel:${parseTel(meta?.telephone || '')}}`}>{imgElement}</a>
          </MobileView>
        </>
      )
    }}
  />
)

ImageContact.defaultProps = {
  className: 'w-auto',
  style: {},
}

export default ImageContact
