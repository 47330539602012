export const MENUS_MAIN = [
  { href: '/nursery', text: '施設案内' },
  { href: '/program', text: '療育案内' },
  { href: '/entrance', text: '入所案内' },
  { href: '/about#company', text: '施設概要' },
  { href: '/about', text: '交通アクセス' }, // 施設概要と同じページに飛ばすが地図のところへページ内リンクに
]

export const MENUS_SIDE = [...MENUS_MAIN]

export const MENUS_FIRST_VIEW = [
  { href: '/nursery', text: '施設案内', src: 'icon-nursery.png' },
  { href: '/program', text: '療育案内', src: 'icon-program.png' },
  { href: '/entrance', text: '入所案内', src: 'icon-entrance.png' },
  { href: '/about', text: '交通アクセス', src: 'icon-about.png' }, // 施設概要と同じページに飛ばすが地図のところへページ内リンクに
]

export const MENUS_NAV_SP = [
  { href: '/nursery', text: '施設案内' },
  { href: '/program', text: '療育案内' },
  { href: '/entrance', text: '入所案内' },
  { href: '/about', text: '交通アクセス' },
]

export const WEEKDAYS = ['月', '火', '水', '木', '金', '土', '日']

// '月', '火', '水', '木', '金', '土', '日'
export const WORKDAYS_CHILD_DEVELOPMENT = ['', '▲', '', '', '▲', '', '-']
export const WORKDAYS_AFTER_SCHOOL = ['▲', '▲', '▲', '▲', '▲', '▲', '-']

export default {
  MENUS_MAIN,
  MENUS_SIDE,
  MENUS_FIRST_VIEW,
  MENUS_NAV_SP,
  WEEKDAYS,
  WORKDAYS_CHILD_DEVELOPMENT,
  WORKDAYS_AFTER_SCHOOL,
}
