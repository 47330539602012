import React from 'react'
import { img } from '@/tools/image'
import { animateScroll as scroll } from 'react-scroll'

interface Props {
  footerOffsetY: number
}

const baseMb = 16

export const ScrollButtonToTop: React.FC<Props> = ({ footerOffsetY }) => {
  const scrollToTop = () => scroll.scrollToTop()
  const style = React.useMemo(() => {
    const mb = footerOffsetY > 0 ? footerOffsetY + baseMb : baseMb
    return mb
      ? {
          marginBottom: `${mb}px`,
        }
      : {}
  }, [footerOffsetY])

  return (
    <img
      className="h-56 cursor-pointer mr-28"
      style={style}
      onClick={() => scrollToTop()}
      src={img('back-to-top@2x.png')}
    />
  )
}

export default ScrollButtonToTop
