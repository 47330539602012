import React from 'react'
import { isMobile } from 'react-device-detect'
import { StaticQuery, graphql } from 'gatsby'
import { TelephoneLinkQuery } from '../../types/graphql-types'
import { parseTel } from '@/tools/meta'

interface Props {
  prefix?: string
}

const query = graphql`
  query TelephoneLink {
    site {
      siteMetadata {
        telephone
      }
    }
  }
`

const TelephoneLink: React.FC<Props> = ({ prefix }) => (
  <StaticQuery
    query={query}
    render={(data: TelephoneLinkQuery) => {
      const meta = data?.site?.siteMetadata
      const text = meta?.telephone || ''

      if (!isMobile) {
        return (
          <>
            {prefix}
            {text}
          </>
        )
      }

      return (
        <>
          {prefix}
          <a href={`tel:${parseTel(text)}}`}>{text}</a>
        </>
      )
    }}
  />
)

TelephoneLink.defaultProps = {
  prefix: 'TEL. ',
}

export default TelephoneLink
