import React from 'react'
import { Link } from 'gatsby'

interface Props {
  menus: { href: string; text: string }[]
  ulClass?: string
  liClass?: string
}

const FooterMenu: React.FC<Props> = ({ menus, ulClass, liClass }) => {
  return (
    <ul className={ulClass}>
      {menus.map((menu, index) => (
        <li className={menus.length === index ? '' : liClass} key={index}>
          <Link to={menu.href}>{menu.text}</Link>
        </li>
      ))}
    </ul>
  )
}

FooterMenu.defaultProps = {
  ulClass: '',
  liClass: '',
}

export default FooterMenu
