import React from 'react'
import { Link } from 'gatsby'
import { img } from '@/tools/image'
import Constants from '@/constants'

interface Props {
  hideNavMenu?: boolean
  title: string
}

const NavMenu: React.FC<Props> = ({ hideNavMenu, title }) => {
  if (hideNavMenu) {
    return <></>
  }
  return (
    <nav id="header" className="fixed w-full bg-purple-300 text-white  z-30 top-0">
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link to="/">
            <h1>
              <img className="h-8" src={img('logo-white@2x.png')} alt={title} />
            </h1>
          </Link>
        </div>

        {/* <div className="block lg:hidden pr-4">
          <button id="nav-toggle" className="flex items-center p-1 text-white-800 hover:text-gray-900">
            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div> */}

        <div
          className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {Constants.MENUS_NAV_SP.map((menu) => (
              <li className="mr-3" key={menu.text}>
                <Link to={menu.href} className="inline-block py-2 px-4 no-underline">
                  {menu.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  )
}

NavMenu.defaultProps = {
  hideNavMenu: false,
}

export default NavMenu
